import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import Image from "../../components/image"
import ImageHotspot from "../../components/image-hotspot"
import ImageHotspots from "../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../components/image-hotspots-modal-template"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import VideoPlayer from "../../components/video-player"

const Katalog12 = () => {
  const data = useStaticQuery(graphql`
    query {
      stift: file(relativePath: { eq: "katalog/plan-stift.jpg" }) {
        ...largeImage
      }
      innenhof: file(
        relativePath: { eq: "videos/poster-katalog-innenhof.jpg" }
      ) {
        ...largeImage
      }
      kapelle: file(relativePath: { eq: "videos/poster-katalog-kapelle.jpg" }) {
        ...largeImage
      }
      karzer: file(relativePath: { eq: "videos/poster-katalog-karzer.jpg" }) {
        ...largeImage
      }
      keller: file(relativePath: { eq: "videos/poster-katalog-keller.jpg" }) {
        ...largeImage
      }
      ochsenstall: file(
        relativePath: { eq: "videos/poster-katalog-ochsenstall.jpg" }
      ) {
        ...largeImage
      }
      pforte: file(relativePath: { eq: "videos/poster-katalog-pforte.jpg" }) {
        ...largeImage
      }
      speisesaal: file(
        relativePath: { eq: "videos/poster-katalog-speisesaal.jpg" }
      ) {
        ...largeImage
      }
      stifshof: file(
        relativePath: { eq: "videos/poster-katalog-stiftshof.jpg" }
      ) {
        ...largeImage
      }
      studierstube: file(
        relativePath: { eq: "videos/poster-katalog-studierstube.jpg" }
      ) {
        ...largeImage
      }
    }
  `)
  return (
    <Layout backdrop="aussen">
      <Seo title="Das Tübinger Stift damals und heute" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Das Tübinger Stift damals und heute",
                link: "/k/12",
              },
            ]}
          />
          <PageTitle>Das Tübinger Stift damals und heute</PageTitle>
          <Constrain>
            <ImageHotspots
              image={
                <Image
                  image={data.stift.childImageSharp.gatsbyImageData}
                  alt=""
                />
              }
            >
              <ImageHotspot x={40} y={55} name="stift-01">
                <ImageHotspotsModalTemplate title="Innenhof">
                  <VideoPlayer
                    src="katalog/12-innenhof"
                    poster={data.innenhof}
                  />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={67} y={63} name="stift-02">
                <ImageHotspotsModalTemplate title="Kapelle">
                  <VideoPlayer src="katalog/12-kapelle" poster={data.kapelle} />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={78} y={50} name="stift-03">
                <ImageHotspotsModalTemplate title="Karzer">
                  <VideoPlayer src="katalog/12-karzer" poster={data.karzer} />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={37} y={37} name="stift-04">
                <ImageHotspotsModalTemplate title="Keller">
                  <VideoPlayer src="katalog/12-keller" poster={data.keller} />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={47} y={82} name="stift-05">
                <ImageHotspotsModalTemplate title="Ochsenstall">
                  <VideoPlayer
                    src="katalog/12-ochsenstall"
                    poster={data.ochsenstall}
                  />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={18} y={88} name="stift-06">
                <ImageHotspotsModalTemplate title="Pforte">
                  <VideoPlayer src="katalog/12-pforte" poster={data.pforte} />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={15} y={55} name="stift-07">
                <ImageHotspotsModalTemplate title="Speisesaal">
                  <VideoPlayer
                    src="katalog/12-speisesaal"
                    poster={data.speisesaal}
                  />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={60} y={20} name="stift-08">
                <ImageHotspotsModalTemplate title="Stiftshof">
                  <VideoPlayer
                    src="katalog/12-stiftshof"
                    poster={data.stiftshof}
                  />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
              <ImageHotspot x={15} y={72} name="stift-09">
                <ImageHotspotsModalTemplate title="Studierstube">
                  <VideoPlayer
                    src="katalog/12-studierstube"
                    poster={data.studierstube}
                  />
                </ImageHotspotsModalTemplate>
              </ImageHotspot>
            </ImageHotspots>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog12
